/* eslint-disable no-unused-vars */
import axios from "axios";
import { failed, formatErrorResponse, start, success } from "../../utils/actions";

import {
  composeFormData,
  ENDPOINT_ACCOUNT_INFO,
  ENDPOINT_CHANGE_PASSWORD,
  ENDPOINT_USER_INFO,
  ENDPOINT_PROFILE,
  ENDPOINT_USER_WORK,
  ENDPOINT_COMPANY,
  ENDPOINT_USER_PROJECT,
  ENDPOINT_USER_EDUCATION,
  ENDPOINT_PROFILE_SHARE,
  ENDPOINT_DEV_PROFILE,
  // ENDPOINT_DEVELOPER_TEST_REQUEST,
  ENDPOINT_GIGCV_EXPORT,
  ENDPOINT_CODERBYTE_ASSESSMENT_INVITATION,
} from "../../utils/api";
import { getUser } from "../../utils/auth";
import * as actionTypes from "../../configs/constants/ActionTypes";
import { verify, logout } from "./AuthActions";
import { openAlertModal } from "../../utils/modals";

const handleError = (error) => {
  let errMsg;
  if (error?.response?.data) {
    // Request made and server responded
    // eslint-disable-next-line prefer-destructuring
    errMsg = Object.values(error.response.data)[0][0];
  } else {
    errMsg = "Sorry, an error occurred.";
  }
  openAlertModal(errMsg, true);
};

export function updateAuthUser(user) {
  return (dispatch, sideEffect = null) => {
    const headers = {};
    const data = user;
    // TODO: Will be used once i start implement profiles
    // if (user.image) {
    //   headers["Content-Type"] = "multipart/form-data";
    //   data = composeFormData(user);
    // }
    axios
      .patch(ENDPOINT_USER_INFO, data, { headers })
      .then(() => {
        if (user?.disagreed_at) {
          return dispatch(logout());
        }

        openAlertModal("Account Details Updated.");
        if (sideEffect) sideEffect();

        return dispatch(success(actionTypes.SET_BUTTON, false));
      })
      .catch((error) => {
        handleError(error);
        dispatch(success(actionTypes.SET_BUTTON, false));
      });
  };
}

export function updateAccountInfo(user) {
  // Requires password and limited to a few account fields
  return (dispatch, sideEffect = null) => {
    axios
      .patch(`${ENDPOINT_ACCOUNT_INFO}${getUser().id}/`, user)
      .then(() => {
        openAlertModal("Account Email Updated.");

        if (sideEffect) sideEffect();
        return dispatch(success(actionTypes.SET_BUTTON, false));
      })
      .catch((error) => {
        handleError(error);

        dispatch(success(actionTypes.SET_BUTTON, false));
      });
  };
}

export function updatePassword(credentials) {
  return (dispatch, sideEffect = null) => {
    axios
      .post(ENDPOINT_CHANGE_PASSWORD, credentials)
      .then(() => {
        openAlertModal("Account Password Updated.");

        if (sideEffect) sideEffect();
        return dispatch(success(actionTypes.SET_BUTTON, false));
      })
      .catch((error) => {
        handleError(error);
        dispatch(success(actionTypes.SET_BUTTON, false));
      });
  };
}

export function updateProfile(
  id,
  profileData,
  stage,
  isCompany = false,
  silent = false,
  { successCb, failureCb } = {},
) {
  return (dispatch) => {
    dispatch(start(actionTypes.UPDATE_PROFILE_START));

    const request_method = id ? "patch" : "post";

    const headers = {};
    let data = profileData;

    if (profileData && (profileData.id_document || (profileData.user && profileData.user.image))) {
      headers["Content-Type"] = "multipart/form-data";
      data = composeFormData(profileData);
    }

    axios
      .request({
        url: isCompany ? ENDPOINT_COMPANY : ENDPOINT_PROFILE,
        method: request_method,
        data,
        headers,
      })
      .then(async (response) => {
        if (successCb) successCb();

        await dispatch(verify({ silent }));
        dispatch(success(actionTypes.UPDATE_PROFILE_SUCCESS, { ...response.data, stage }));
      })
      .catch((error) => {
        const errMessage = formatErrorResponse(error);
        if (failureCb) failureCb(errMessage);
        dispatch(failed(actionTypes.UPDATE_PROFILE_FAILED, errMessage));
      });
  };
}

export function updateDevProfile(id, profileData, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(actionTypes.UPDATE_DEV_PROFILE_START));

    axios
      .patch(`${ENDPOINT_DEV_PROFILE}${id}/`, profileData)
      .then(async (response) => {
        if (success) successCb();
        dispatch(success(actionTypes.UPDATE_DEV_PROFILE_SUCCESS, { ...response.data }));
      })
      .catch((error) => {
        if (failureCb) failureCb();
        dispatch(failed(actionTypes.UPDATE_DEV_PROFILE_FAILED, formatErrorResponse(error)));
      });
  };
}

export function updateWork(id, data, deleteExperience = false) {
  const method = deleteExperience
    ? { verb: "delete", url: `${ENDPOINT_USER_WORK + id}/` }
    : id
    ? { verb: "patch", url: `${ENDPOINT_USER_WORK + id}/` }
    : { verb: "post", url: ENDPOINT_USER_WORK };

  return (dispatch, { successCb, failureCb }) => {
    axios
      .request({
        url: method.url,
        method: method.verb,
        data,
      })
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(actionTypes.UPDATE_WORK_SUCCESS, response.data));
      })
      .catch((error) => {
        if (failureCb) failureCb();
        dispatch(failed(actionTypes.UPDATE_WORK_FAILED, formatErrorResponse(error)));
      });
  };
}

export function updateProject(id, data, deleteProject = false) {
  const method = deleteProject
    ? { verb: "delete", url: `${ENDPOINT_USER_PROJECT + id}/` }
    : id
    ? { verb: "patch", url: `${ENDPOINT_USER_PROJECT + id}/` }
    : { verb: "post", url: ENDPOINT_USER_PROJECT };

  return (dispatch, { successCb, failureCb }) => {
    axios
      .request({
        url: method.url,
        method: method.verb,
        data,
      })
      .then(async (response) => {
        if (successCb) successCb();
        dispatch(success(actionTypes.UPDATE_PROJECTS_SUCCESS, response.data));
      })
      .catch((error) => {
        if (failureCb) failureCb();
        dispatch(failed(actionTypes.UPDATE_PROJECTS_FAILED, formatErrorResponse(error)));
      });
  };
}

export function updateEducation(id, data, deleteEducation = false) {
  const method = deleteEducation
    ? { verb: "delete", url: `${ENDPOINT_USER_EDUCATION + id}/` }
    : id
    ? { verb: "patch", url: `${ENDPOINT_USER_EDUCATION + id}/` }
    : { verb: "post", url: ENDPOINT_USER_EDUCATION };

  return (dispatch, { successCb, failureCb }) => {
    axios
      .request({
        url: method.url,
        method: method.verb,
        data,
      })
      .then(async (response) => {
        if (successCb) successCb();
        dispatch(success(actionTypes.UPDATE_EDUCATION_SUCCESS, response.data));
      })
      .catch((error) => {
        if (failureCb) failureCb();
        dispatch(failed(actionTypes.UPDATE_EDUCATION_FAILED, formatErrorResponse(error)));
      });
  };
}

export function updateOnboardingStage(stage) {
  return (dispatch) => {
    dispatch(success(actionTypes.UPDATE_ONBOARDING_STAGE, { stage }));
  };
}

export function deactivateAccount(cb) {
  return (dispatch) => {
    axios
      .post(`${ENDPOINT_ACCOUNT_INFO}deactivate/`, {})
      .then(() => cb && cb())
      .catch(() => {});
  };
}

export function updateCompanyStart(id, company) {
  return {
    type: actionTypes.UPDATE_COMPANY_START,
    id,
    company,
  };
}

export function updateCompanySuccess(company, id, stage) {
  return {
    type: actionTypes.UPDATE_COMPANY_SUCCESS,
    company,
    id,
    stage,
  };
}

export function updateCompanyFailed(error, id, data) {
  return {
    type: actionTypes.UPDATE_COMPANY_FAILED,
    error,
    id,
    data,
  };
}

export function updateCompany(id, company, stage = null, silent = true) {
  return (dispatch) => {
    dispatch(updateCompanyStart(id, company));
    const request_method = id ? "patch" : "post";
    const headers = {};
    let data = company;

    if (company && company.user && (company.user.image || company.user.id_document)) {
      headers["Content-Type"] = "multipart/form-data";
      data = composeFormData(company);
    }

    axios
      .request({
        url: ENDPOINT_COMPANY,
        method: request_method,
        data,
        headers,
      })
      .then(async (response) => {
        await dispatch(verify({ silent }));
        dispatch(updateCompanySuccess(response.data, id, stage));
      })
      .catch((error) => {
        dispatch(updateCompanyFailed(error.response ? error.response.data : null, id, data));
      });
  };
}

export function shareProfile(id, is_link_enabled, { method = "patch", silent = true } = {}) {
  return (dispatch) => {
    if (!silent) dispatch(start(actionTypes.SHARE_PROFILE_START));

    const data = { is_link_enabled };
    const query = method === "patch" ? `${id}/` : "";

    axios
      .request({
        url: `${ENDPOINT_PROFILE_SHARE}${query}`,
        method,
        data,
      })
      .then(async (response) => {
        await dispatch(verify({ silent }));
        dispatch(success(actionTypes.SHARE_PROFILE_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(actionTypes.SHARE_PROFILE_FAILED, formatErrorResponse(error)));
      });
  };
}

export function requestTest(data, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(actionTypes.REQUEST_TEST_START));

    axios
      .post(`${ENDPOINT_CODERBYTE_ASSESSMENT_INVITATION}`, data)
      .then(async (response) => {
        if (success) successCb();

        openAlertModal(
          response.data.message ||
            "Assessment requested successfully! Please check your email for the link(s)",
        );
        dispatch(success(actionTypes.REQUEST_TEST_SUCCESS, { ...response.data }));
      })
      .catch((error) => {
        if (failureCb) failureCb();

        const errorResponses = error.response?.data?.responses;

        // Extract error messages if any responses contain "failed" status
        if (errorResponses) {
          const errorMessages = errorResponses
            .filter((res) => res.status === "failed")
            .map((res) => res.message)
            .join(", ");

          openAlertModal(
            errorMessages || "An error occurred while requesting the assessment.",
            true,
          );
          dispatch(failed(actionTypes.REQUEST_TEST_FAILED, { message: errorMessages }));
        } else {
          // Fallback for unexpected errors
          const errorMessage = formatErrorResponse(error);
          openAlertModal(errorMessage || "An unexpected error occurred.", true);
          dispatch(failed(actionTypes.REQUEST_TEST_FAILED, { message: errorMessage }));
        }
      });
  };
}

export function gigcvExport({ successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(actionTypes.EXPORT_GIG_CV_START));

    axios
      .get(`${ENDPOINT_GIGCV_EXPORT}`, { responseType: "blob" })
      .then(async (response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "gigcv.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
        fileLink.parentNode.removeChild(fileLink);
        window.URL.revokeObjectURL(fileURL);

        if (success) successCb();

        dispatch(success(actionTypes.EXPORT_GIG_CV_SUCCESS, { ...response.data }));
      })
      .catch((error) => {
        if (failureCb) failureCb();
        dispatch(failed(actionTypes.EXPORT_GIG_CV_FAILED, formatErrorResponse(error)));
      });
  };
}
